import { Grid, Layout } from "antd";
import Spinner from "common/components/spinner/spinner";
import { useState } from "react";
import Footer from "./footer";
import Header from "./header";
import LayoutCss from "./layout.module.scss";
import Sider from "./sider";
import { isNonProdEnvironment } from "../utilities/systemUtility";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function LoggedInPageLayout({ children }) {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const envStyles = isNonProdEnvironment() ? { marginTop: 25 } : null;

  return (
    <Layout style={envStyles}>
      <Sider
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        toggle={toggle}
      />
      <Layout className={LayoutCss["site-layout"]}>
        {screens.lg === undefined || screens.lg ? null : (
          <Header toggle={toggle} />
        )}
        <Content className={LayoutCss.appContentArea}>
          {children}
          <Spinner />
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}
