import K from "utilities/constants";
import {
  convertIntoDashUSFormat,
  convertIntoUTCDashUSFormat,
  getMonthName,
} from "utilities/dateUtility";
import { checkNullPlaceHolder } from "utilities/generalUtility";
import { displayDollar, stringSorting } from "utilities/tableUtility";

export const isManualHeaderProperties = {
  checkboxSelection: true,
  headerCheckboxSelection: true,
  filter: "agSetColumnFilter",
  sortable: false,
  headerCheckboxSelectionFilteredOnly: true,
  cellRenderer: (params) => params.value,
  valueGetter: (params) => (params.data.isManual === 1 ? "Manual" : "Auto"),
  cellClassRules: {
    "rag-red": (params) => params.value === "Auto",
    "rag-green": (params) => params.value === "Manual",
  },
  rowSelection: {
    mode: "multiRow",
    selectAll: "filtered",
  },
  filterParams: {
    values: ["Auto", "Manual"],
  },
};
export const nullRenderer = {
  cellRenderer: (params) => checkNullPlaceHolder(params.value),
};

export const createdAtRenderer = {
  cellRenderer: (params) =>
    params.data.isManual
      ? convertIntoDashUSFormat(params.value)
      : convertIntoUTCDashUSFormat(params.value),
};
export const tooltipCreatedAtRenderer = {
  tooltipComponent: (params) => (
    <div className="client-list-tooltip">
      {params.data.isManual
        ? convertIntoDashUSFormat(params.value)
        : convertIntoUTCDashUSFormat(params.value)}
    </div>
  ),
};
export const startDateRenderer = {
  cellRenderer: (params) =>
    convertIntoDashUSFormat(params.data.candidateJobEntry?.startDate, false),
};
export const tooltipStartDateRenderer = {
  tooltipComponent: (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.data.candidateJobEntry?.startDate, false)}
    </div>
  ),
};
export const amountRenderer = {
  cellRenderer: (params) =>
    [displayDollar(+params.value), `(${params.data.currency})`].join(" "),
};
export const tooltipAmountRenderer = {
  tooltipComponent: (params) => (
    <div className="client-list-tooltip">
      {[displayDollar(+params.value), `(${params.data.currency})`].join(" ")}
    </div>
  ),
};
export const duplicateAmountRenderer = {
  cellRenderer: (params) =>
    [
      displayDollar(+params.value?.toFixed(2)),
      `(${params.data.convertToCurrency})`,
    ].join(" "),
};
export const tooltipDuplicateAmountRenderer = {
  tooltipComponent: (params) => (
    <div className="client-list-tooltip">
      {[
        displayDollar(+params.value?.toFixed(2)),
        `${params.data.convertToCurrency})`,
      ].join(" ")}
    </div>
  ),
};

export const tooltipTagRenderer = (params, key) => {
  return (
    <div className="client-list-tooltip">
      {key
        ? params.data[key].map((item) => item.name).join(" ,")
        : params.value.name}
    </div>
  );
};

export const toolTipInvoiceNumber = (params) => (
  <div className="client-list-tooltip">
    {params.value ? params.value : K.NullPlaceholder}
  </div>
);

export const toolTipInvoiceDueDate = (params) => (
  <div className="client-list-tooltip">
    {convertIntoDashUSFormat(params.value, false)}
  </div>
);

export const toolTipInvoiceMonth = (params) => (
  <div className="client-list-tooltip">{getMonthName(params.value)}</div>
);
export const toolTipInvoiceAmount = (params) => (
  <div className="client-list-tooltip">{displayDollar(params.value)}</div>
);

export const clientBilling = [
  {
    headerName: "Auto/Manual",
    field: "isManual",
    sortable: true,
    ...isManualHeaderProperties,
  },
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Billing Type",
    field: "billingTypeName",
    sortable: true,
    tooltipField: "billingTypeName",
    ...nullRenderer,
  },
  {
    headerName: "Description",
    field: "title",
    tooltipField: "title",
    sortable: true,
    ...nullRenderer,
  },
  {
    headerName: "Create Date",
    field: "createdAt",
    sortable: true,
    tooltipField: "createdAt",
    ...tooltipCreatedAtRenderer,
    ...createdAtRenderer,
  },
  {
    headerName: "Original Amount",
    field: "amount",
    sortable: true,
    ...tooltipAmountRenderer,
    ...amountRenderer,
  },
  {
    headerName: "Billed Amount",
    field: "dublicateAmount",
    sortable: true,
    ...tooltipDuplicateAmountRenderer,
    ...duplicateAmountRenderer,
  },
  {
    headerName: "Source",
    field: "source.name",
    tooltipField: "source.name",
    sortable: true,
    ...nullRenderer,
  },
  {
    headerName: "Quantity",
    field: "quantity",
    sortable: true,
    tooltipField: "quantity",
    ...nullRenderer,
  },
];

export const jobBilling = [
  {
    headerName: "Auto/Manual",
    field: "isManual",
    sortable: true,
    ...isManualHeaderProperties,
  },
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Billing Type",
    field: "billingTypeName",
    sortable: true,
    tooltipField: "billingTypeName",
  },
  {
    headerName: "Candidate Name",
    field: "candidateName",
    tooltipField: "candidateName",
    sortable: true,
    ...nullRenderer,
  },
  {
    headerName: "Job Title",
    field: "jobTitle",
    sortable: true,
    tooltipField: "jobTitle",
  },
  {
    headerName: "Create Date",
    field: "createdAt",
    tooltipField: "createdAt",
    sortable: true,
    ...createdAtRenderer,
    ...tooltipCreatedAtRenderer,
  },
  {
    headerName: "Start Date",
    field: "candidateJobEntry.startDate",
    tooltipField: "candidateJobEntry.startDate",
    sortable: true,
    ...startDateRenderer,
    ...tooltipStartDateRenderer,
  },
  {
    headerName: "Orignal Amount",
    field: "amount",
    tooltipField: "amount",
    sortable: true,
    ...amountRenderer,
    ...tooltipAmountRenderer,
  },
  {
    headerName: "Billed Amount",
    field: "dublicateAmount",
    tooltipField: "dublicateAmount",
    sortable: true,
    ...duplicateAmountRenderer,
    ...tooltipDuplicateAmountRenderer,
  },
  {
    headerName: "ATS",
    field: "jobSource.atsLookUp.name",
    sortable: true,
    tooltipField: "jobSource.atsLookUp.name",
    ...nullRenderer,
  },

  {
    headerName: "ATS ID",
    field: "jobSource.extAtsId",
    sortable: true,
    tooltipField: "jobSource.extAtsId",
    ...nullRenderer,
  },
  {
    headerName: "Job Location",
    field: "jobSource.jobLocation.name",
    sortable: true,
    tooltipField: "jobSource.jobLocation.name",
    ...nullRenderer,
  },

  {
    headerName: "Job Department",
    field: "jobSource.jobDepartment.name",
    tooltipField: "jobSource.jobDepartment.name",
    sortable: true,
    ...nullRenderer,
  },
  {
    headerName: "Job Group",
    field: "jobSource.jobGroup.name",
    sortable: true,
    tooltipField: "jobSource.jobGroup.name",
    ...nullRenderer,
  },
  {
    headerName: "Credit Reason",
    field: "issueCreditReason",
    sortable: true,
    tooltipField: "issueCreditReason",
  },
];
